import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, PaletteMode, Theme } from '@mui/material';
import { getDesignTokens } from '../theme';

// Define context type
type ThemeContextType = {
  mode: PaletteMode;
  toggleColorMode: () => void;
};

// Create context with default values
const ThemeContext = createContext<ThemeContextType>({
  mode: 'light',
  toggleColorMode: () => {},
});

// Custom hook to use the theme context
export const useThemeContext = () => useContext(ThemeContext);

// Theme provider component
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Get saved theme from localStorage or use 'light' as default
  const [mode, setMode] = useState<PaletteMode>(() => {
    const savedMode = localStorage.getItem('themeMode');
    return (savedMode as PaletteMode) || 'light';
  });

  // Toggle between light and dark mode
  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Save theme preference to localStorage
  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  // Create theme based on current mode
  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode,
        ...(mode === 'light'
          ? {
              // Light mode
              primary: {
                main: '#ff6b6b',
                dark: '#e53935',
                light: '#ff8a80',
                contrastText: '#fff',
              },
              secondary: {
                main: '#ffd700',
                dark: '#c9b200',
                light: '#ffff52',
                contrastText: '#000',
              },
              background: {
                default: '#f5f5f5',
                paper: '#ffffff',
              },
              text: {
                primary: '#333333',
                secondary: '#666666',
              },
            }
          : {
              // Dark mode
              primary: {
                main: '#c62828',
                light: '#ff5f52',
                dark: '#8e0000',
                contrastText: '#fff',
              },
              secondary: {
                main: '#b8860b',
                light: '#ecb443',
                dark: '#875a00',
                contrastText: '#000',
              },
              background: {
                default: '#222222',
                paper: '#333333',
              },
              text: {
                primary: '#ffffff',
                secondary: '#aaaaaa',
              },
            }),
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 600,
        },
        h3: {
          fontWeight: 600,
        },
        h4: {
          fontWeight: 600,
        },
        h5: {
          fontWeight: 500,
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              textTransform: 'none',
              fontWeight: 500,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              boxShadow: mode === 'light' 
                ? '0 4px 12px rgba(0, 0, 0, 0.05)' 
                : '0 4px 12px rgba(0, 0, 0, 0.25)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: mode === 'light' 
                ? '0 2px 8px rgba(0, 0, 0, 0.05)' 
                : '0 2px 8px rgba(0, 0, 0, 0.2)',
            },
          },
        },
      },
    });
  }, [mode]);

  // Context value
  const contextValue = useMemo(
    () => ({
      mode,
      toggleColorMode,
    }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}; 