import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from './contexts/ThemeContext';
import Layout from './components/layout/Layout';
import SecureApp from './components/security/SecureApp';

// Lazy load pages for better performance
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Players = React.lazy(() => import('./pages/Players'));
const PlayerDetail = React.lazy(() => import('./pages/PlayerDetail'));
const BulkPoints = React.lazy(() => import('./pages/BulkPoints'));
const Groups = React.lazy(() => import('./pages/Groups'));
const GroupDetail = React.lazy(() => import('./pages/GroupDetail'));
const Stats = React.lazy(() => import('./pages/Stats'));
const Settings = React.lazy(() => import('./pages/Settings'));
const History = React.lazy(() => import('./pages/History'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

// Loading component for suspense fallback
const Loading = () => <div>Loading...</div>;

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <SecureApp>
        <BrowserRouter>
          <Layout>
            <React.Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/players" element={<Players />} />
                <Route path="/players/bulk-points" element={<BulkPoints />} />
                <Route path="/players/:id" element={<PlayerDetail />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/groups/:id" element={<GroupDetail />} />
                <Route path="/stats" element={<Stats />} />
                <Route path="/history" element={<History />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/404" element={<NotFound />} />
                {/* Catch all route for 404 pages */}
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
            </React.Suspense>
          </Layout>
        </BrowserRouter>
      </SecureApp>
    </ThemeProvider>
  );
}

export default App;
