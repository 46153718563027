/**
 * Security service for the Basketball Effort Tracker application
 * Manages PIN authentication and access control
 */

import axios from 'axios';
import api from './api'; // Import the configured API instance

// Get the current origin for the API URL
const currentOrigin = typeof window !== 'undefined' ? window.location.origin : '';

// API endpoint for authentication - determine the best URL to use
let API_URL = '';
if (process.env.NODE_ENV === 'production' || !currentOrigin.includes('localhost')) {
  // In production or when using a custom domain, use relative paths
  API_URL = '/api';
  console.log('Security: Using relative API path: /api');
} else {
  // Only for localhost development, use the full URL with port 5001
  API_URL = 'http://localhost:5001/api';
  console.log('Security: Using localhost API URL:', API_URL);
}

// Log the API URL for debugging
console.log('Security service using API URL:', API_URL, 'from origin:', currentOrigin);

// Create a dedicated axios instance for authentication requests
const authAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Important for cookies/session handling
  timeout: 10000
});

interface AuthResponse {
  message: string;
  token?: string;
  authenticated?: boolean;
  remainingAttempts?: number;
  lockoutRemaining?: number;
}

/**
 * Authenticate with the server using a PIN
 * @param pin The PIN to authenticate with
 * @returns Promise with authentication result
 */
export const authenticate = async (pin: string): Promise<AuthResponse> => {
  try {
    console.log('Authenticating with API URL:', API_URL);
    const response = await authAxios.post<AuthResponse>('/auth/login', { pin });
    
    // If successful, cache the authenticated state
    if (response.data.token) {
      sessionStorage.setItem('authenticated', 'true');
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Authentication error details:', error);
    if (error.response && error.response.data) {
      return error.response.data;
    }
    // Return a properly formatted error response to prevent undefined errors
    return {
      message: error.message || 'An unexpected error occurred during authentication',
      authenticated: false
    };
  }
};

/**
 * Check if the user is authenticated
 * @returns Promise resolving to boolean indicating if user is authenticated
 */
export const isAuthenticated = async (): Promise<boolean> => {
  try {
    // First check sessionStorage to avoid unnecessary API calls
    const cachedAuth = sessionStorage.getItem('authenticated');
    if (cachedAuth === 'true') {
      return true;
    }
    
    // If no cached auth, check with server
    const response = await authAxios.get<AuthResponse>('/auth/check');
    
    // Cache the result to reduce API calls
    if (response.data.authenticated) {
      sessionStorage.setItem('authenticated', 'true');
    }
    
    return response.data.authenticated || false;
  } catch (error: any) {
    console.error('Authentication check failed:', error);
    // Don't try to access localStorage if window is undefined (SSR context)
    if (typeof window !== 'undefined') {
      // Clear cached auth state on error
      sessionStorage.removeItem('authenticated');
    }
    return false;
  }
};

/**
 * Clear authentication data
 * @returns Promise resolving when logout is complete
 */
export const clearAuthentication = async (): Promise<void> => {
  try {
    // Clear session storage
    sessionStorage.removeItem('authenticated');
    
    // Call logout API to clear server-side auth
    await authAxios.post('/auth/logout');
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

// Create a named const object before exporting as default
const securityService = {
  authenticate,
  isAuthenticated,
  clearAuthentication
};

export default securityService; 