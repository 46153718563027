import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Dialog,
  useTheme,
  Slide,
  Fade,
  CircularProgress
} from '@mui/material';
import {
  LockOutlined as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Sports as SportsIcon
} from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { authenticate } from '../../services/securityService';

// Props for the PinScreen component
interface PinScreenProps {
  onAuthenticate: () => void;
}

// Slide up transition for the PIN dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PinScreen: React.FC<PinScreenProps> = ({ onAuthenticate }) => {
  const theme = useTheme();
  const [pin, setPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLockdown, setShowLockdown] = useState(false);
  const [countdown, setCountdown] = useState(0);

  // Check if there's a lockout from the server
  useEffect(() => {
    const checkLockout = async () => {
      try {
        // This is a lightweight check just to see if we're locked out
        const response = await authenticate('');
        
        if (response.lockoutRemaining) {
          setShowLockdown(true);
          setCountdown(response.lockoutRemaining * 60); // Convert minutes to seconds
        }
      } catch (error) {
        console.error("Error checking lockout status:", error);
        // Don't show any errors here, we're just checking
      }
    };
    
    checkLockout();
  }, []);

  // Countdown timer for lockout
  useEffect(() => {
    if (countdown > 0 && showLockdown) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      
      return () => clearTimeout(timer);
    } else if (countdown === 0 && showLockdown) {
      setShowLockdown(false);
    }
  }, [countdown, showLockdown]);

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only numbers and limit to 4 digits
    if (/^\d*$/.test(value) && value.length <= 4) {
      setPin(value);
      if (error) setError('');
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      // Validate PIN before sending
      if (!pin || pin.length !== 4 || !/^\d{4}$/.test(pin)) {
        setError('Please enter a valid 4-digit PIN');
        setIsSubmitting(false);
        return;
      }
      
      console.log('Submitting PIN authentication request');
      const response = await authenticate(pin);
      console.log('Auth response:', response);
      
      if (response.token) {
        // Success - call the onAuthenticate callback
        onAuthenticate();
      } else if (response.lockoutRemaining) {
        // User is locked out
        setShowLockdown(true);
        setCountdown(response.lockoutRemaining * 60); // Convert minutes to seconds
        setPin('');
      } else if (response.remainingAttempts) {
        // Failed login with remaining attempts info
        setError(`Invalid PIN. ${response.remainingAttempts} attempts remaining before lockout.`);
        setPin('');
      } else {
        // Failed login, show error
        setError(response.message || 'Invalid PIN. Please try again.');
        setPin('');
      }
    } catch (error: any) {
      console.error('Authentication error:', error);
      
      // Provide more specific error messages based on the error type
      if (error.response) {
        if (error.response.status === 401) {
          setError('Invalid PIN. Please try again.');
        } else if (error.response.status === 429) {
          // Too many requests - show lockdown
          setShowLockdown(true);
          setCountdown(error.response.data?.lockoutRemaining * 60 || 15 * 60); // Default to 15 min
        } else {
          setError(`Server error (${error.response.status}). Please try again.`);
        }
      } else if (error.message && error.message.includes('Network Error')) {
        setError('Network error. Please check your connection and try again.');
      } else {
        setError('An error occurred. Please try again.');
      }
      
      setPin('');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && pin.length === 4 && !showLockdown && !isSubmitting) {
      handleSubmit();
    }
  };

  const formatCountdown = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const renderLockdownContent = () => (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h5" component="h2" color="error" gutterBottom>
        Access Temporarily Locked
      </Typography>
      
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          width: 100,
          height: 100,
          borderRadius: '50%',
          bgcolor: 'error.main',
          color: 'white',
          margin: '20px auto',
          fontSize: '1.5rem',
          fontWeight: 'bold'
        }}
      >
        {formatCountdown()}
      </Box>
      
      <Typography variant="body1" gutterBottom>
        Too many failed login attempts.
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Please wait until the timer expires and try again.
      </Typography>
    </Box>
  );

  return (
    <Dialog 
      open={true} 
      fullScreen 
      TransitionComponent={Transition}
      transitionDuration={{ enter: 500, exit: 300 }}
    >
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.mode === 'dark' ? 'rgba(10, 10, 10, 0.95)' : 'rgba(250, 250, 250, 0.95)',
          backgroundImage: theme.palette.mode === 'dark' 
            ? 'url("data:image/svg+xml,%3Csvg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cg fill="%239C92AC" fill-opacity="0.05"%3E%3Cpath d="M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z" /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")'
            : 'url("data:image/svg+xml,%3Csvg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cg fill="%23000000" fill-opacity="0.03"%3E%3Cpath d="M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z" /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
        }}
      >
        <Fade in={true} timeout={1000}>
          <Paper
            elevation={12}
            sx={{
              p: 5,
              width: '90%',
              maxWidth: 400,
              borderRadius: 3,
              textAlign: 'center',
              backdropFilter: 'blur(10px)',
              background: theme.palette.mode === 'dark' 
                ? 'rgba(30, 30, 30, 0.8)' 
                : 'rgba(255, 255, 255, 0.9)',
              border: '1px solid',
              borderColor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.1)' 
                : 'rgba(0, 0, 0, 0.1)',
              boxShadow: theme.palette.mode === 'dark'
                ? '0 8px 32px rgba(0, 0, 0, 0.5)'
                : '0 8px 32px rgba(0, 0, 0, 0.1)',
            }}
          >
            {showLockdown ? renderLockdownContent() : (
              <>
                <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box
                    sx={{
                      mb: 2,
                      p: 2,
                      borderRadius: '50%',
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <SportsIcon fontSize="large" />
                  </Box>
                  
                  <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                    Secure Access
                  </Typography>
                  
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                    Please enter your PIN to access this system.
                  </Typography>
                </Box>

                <TextField
                  label="PIN Code"
                  variant="outlined"
                  fullWidth
                  type={showPin ? 'text' : 'password'}
                  value={pin}
                  onChange={handlePinChange}
                  onKeyDown={handleKeyDown}
                  error={!!error}
                  helperText={error}
                  autoFocus
                  disabled={isSubmitting}
                  sx={{ mb: 3 }}
                  inputProps={{
                    maxLength: 4,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    style: { letterSpacing: '0.5em', fontSize: '1.2em', textAlign: 'center' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPin(!showPin)}
                          edge="end"
                          disabled={isSubmitting}
                        >
                          {showPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={pin.length !== 4 || isSubmitting}
                  size="large"
                  sx={{
                    py: 1.5,
                    fontWeight: 'bold',
                    letterSpacing: 1,
                    borderRadius: 2,
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </>
            )}
          </Paper>
        </Fade>
      </Box>
    </Dialog>
  );
};

export default PinScreen; 