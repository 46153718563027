import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  useTheme,
  useMediaQuery,
  Avatar,
  Tooltip
} from '@mui/material';
import {
  Menu as MenuIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  SportsBasketball as SportsIcon,
  Close as CloseIcon,
  History as HistoryIcon
} from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useThemeContext } from '../../contexts/ThemeContext';

const Header: React.FC = () => {
  const theme = useTheme();
  const { mode, toggleColorMode } = useThemeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
    { text: 'Players', icon: <SportsIcon />, path: '/players' },
    { text: 'Groups', icon: <GroupIcon />, path: '/groups' },
    { text: 'Stats', icon: <BarChartIcon />, path: '/stats' },
    { text: 'History', icon: <HistoryIcon />, path: '/history' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
  ];

  const isActive = (path: string) => {
    if (path === '/' && location.pathname === '/') return true;
    if (path !== '/' && location.pathname.startsWith(path)) return true;
    return false;
  };

  const drawer = (
    <Box sx={{ width: 280 }} role="presentation">
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%',
        background: theme.palette.mode === 'dark' 
          ? 'linear-gradient(180deg, rgba(30, 30, 30, 0.9) 0%, rgba(25, 25, 25, 0.95) 100%)'
          : 'linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(248, 249, 250, 0.95) 100%)',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar 
              sx={{ 
                bgcolor: theme.palette.primary.main, 
                mr: 2,
                width: 40,
                height: 40
              }}
            >
              <SportsIcon />
            </Avatar>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Chargers Effort
            </Typography>
          </Box>
          <IconButton onClick={() => toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        <List sx={{ width: '100%', mt: 2 }}>
          {menuItems.map((item) => {
            const active = isActive(item.path);
            return (
              <ListItem key={item.text} disablePadding sx={{ mb: 1 }}>
                <ListItemButton
                  component={RouterLink} 
                  to={item.path}
                  onClick={() => toggleDrawer(false)}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: active 
                      ? theme.palette.mode === 'light' 
                        ? 'rgba(67, 97, 238, 0.1)' 
                        : 'rgba(67, 97, 238, 0.2)'
                      : 'transparent',
                    color: active ? theme.palette.primary.main : 'inherit',
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'light' 
                        ? 'rgba(67, 97, 238, 0.1)' 
                        : 'rgba(67, 97, 238, 0.2)',
                    },
                    transition: 'all 0.2s ease',
                    pl: 2
                  }}
                >
                  <ListItemIcon 
                    sx={{ 
                      color: active ? theme.palette.primary.main : 'inherit',
                      minWidth: 40,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text}
                    primaryTypographyProps={{ 
                      fontWeight: active ? 600 : 400 
                    }}
                  />
                  {active && (
                    <Box 
                      sx={{ 
                        width: 4,
                        height: 20,
                        borderRadius: 4,
                        bgcolor: theme.palette.primary.main,
                        ml: 1
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        <Box sx={{ mt: 'auto', textAlign: 'center' }}>
          <Button
            onClick={toggleColorMode}
            variant="outlined"
            color="inherit"
            startIcon={mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
            sx={{ mt: 2, width: '100%' }}
          >
            {mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar 
        position="sticky" 
        color="inherit"
        elevation={0}
        sx={{ 
          backdropFilter: 'blur(8px)',
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.8)' 
            : 'rgba(30, 30, 30, 0.8)',
          transition: 'all 0.3s ease',
        }}
      >
        <Toolbar sx={{ minHeight: { xs: 64, sm: 70 } }}>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Avatar 
                  sx={{ 
                    bgcolor: theme.palette.primary.main, 
                    mr: 1.5,
                    width: 32,
                    height: 32
                  }}
                >
                  <SportsIcon sx={{ fontSize: 18 }} />
                </Avatar>
                <Typography 
                  variant="h6" 
                  component="div" 
                  sx={{ fontWeight: 600, fontSize: { xs: '1rem', sm: '1.25rem' } }}
                >
                  Keller Chargers
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
                <Avatar 
                  sx={{ 
                    bgcolor: theme.palette.primary.main, 
                    mr: 1.5,
                    width: 36,
                    height: 36
                  }}
                >
                  <SportsIcon />
                </Avatar>
                <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                  Keller Chargers
                </Typography>
              </Box>
              
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                {menuItems.map((item) => {
                  const active = isActive(item.path);
                  return (
                    <Button
                      key={item.text}
                      component={RouterLink}
                      to={item.path}
                      startIcon={item.icon}
                      sx={{ 
                        mx: 0.5,
                        px: 2,
                        color: active ? theme.palette.primary.main : 'inherit',
                        fontWeight: active ? 600 : 400,
                        position: 'relative',
                        '&::after': active ? {
                          content: '""',
                          position: 'absolute',
                          bottom: -8,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '30%',
                          height: 3,
                          borderRadius: 3,
                          backgroundColor: theme.palette.primary.main,
                        } : {},
                        '&:hover': {
                          background: theme.palette.mode === 'light' 
                            ? 'rgba(67, 97, 238, 0.05)' 
                            : 'rgba(67, 97, 238, 0.1)',
                        }
                      }}
                    >
                      {item.text}
                    </Button>
                  );
                })}
              </Box>
            </>
          )}

          <Tooltip title={mode === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}>
            <IconButton 
              onClick={toggleColorMode} 
              color="inherit"
              sx={{ 
                ml: 1,
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'rotate(15deg)',
                }
              }}
            >
              {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            borderRadius: '0 16px 16px 0',
            width: 280,
          }
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Header; 