import React, { useState, useEffect } from 'react';
import { isAuthenticated } from '../../services/securityService';
import PinScreen from './PinScreen';
import { Box, CircularProgress } from '@mui/material';

interface SecureAppProps {
  children: React.ReactNode;
}

const SecureApp: React.FC<SecureAppProps> = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(true);

  // Check authentication state when component mounts
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const auth = await isAuthenticated();
        setIsAuthorized(auth);
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthorized(false);
      } finally {
        setIsChecking(false);
      }
    };
    
    checkAuth();
    
    // Also set up a listener for when the app comes back into focus
    // to re-check authentication
    const handleFocus = () => {
      checkAuth();
    };
    
    window.addEventListener('focus', handleFocus);
    
    // Set up interval to check auth status every 5 minutes
    const intervalId = setInterval(checkAuth, 5 * 60 * 1000);
    
    return () => {
      window.removeEventListener('focus', handleFocus);
      clearInterval(intervalId);
    };
  }, []);

  // Handle successful authentication
  const handleAuthenticate = () => {
    setIsAuthorized(true);
  };

  // While checking authentication status, show loading indicator
  if (isChecking) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // If not authorized, show the PIN screen
  if (!isAuthorized) {
    return <PinScreen onAuthenticate={handleAuthenticate} />;
  }

  // If authorized, show the actual app content
  return <>{children}</>;
};

export default SecureApp; 