import React from 'react';
import { Box, Container, Paper, useTheme, Typography } from '@mui/material';
import Header from './Header';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme();
  const year = new Date().getFullYear();

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh',
        background: theme.palette.mode === 'light'
          ? 'linear-gradient(145deg, rgba(248, 249, 250, 0.95) 0%, rgba(240, 242, 245, 0.9) 100%)'
          : 'linear-gradient(145deg, rgba(18, 18, 18, 0.95) 0%, rgba(10, 10, 10, 0.9) 100%)',
      }}
    >
      <Header />
      <Container 
        component="main" 
        maxWidth="xl" 
        sx={{ 
          flexGrow: 1, 
          py: { xs: 3, md: 4 },
          px: { xs: 2, sm: 3 }
        }}
      >
        <Paper 
          elevation={0} 
          sx={{ 
            p: { xs: 2, sm: 3 }, 
            borderRadius: 3,
            backgroundColor: theme.palette.mode === 'light' 
              ? 'rgba(255, 255, 255, 0.9)' 
              : 'rgba(30, 30, 30, 0.9)',
            backdropFilter: 'blur(10px)',
            boxShadow: theme.palette.mode === 'light' 
              ? '0 8px 32px rgba(0, 0, 0, 0.08)' 
              : '0 8px 32px rgba(0, 0, 0, 0.2)',
            border: `1px solid ${
              theme.palette.mode === 'light' 
                ? 'rgba(255, 255, 255, 0.8)' 
                : 'rgba(255, 255, 255, 0.05)'
            }`,
            transition: 'all 0.3s ease',
          }}
        >
          {children}
        </Paper>
      </Container>
      <Box 
        component="footer" 
        sx={{ 
          py: 2, 
          px: 2,
          mt: 'auto',
          backdropFilter: 'blur(10px)',
          backgroundColor: theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.8)' 
            : 'rgba(30, 30, 30, 0.8)',
          borderTop: `1px solid ${
            theme.palette.mode === 'light' 
              ? 'rgba(0, 0, 0, 0.05)' 
              : 'rgba(255, 255, 255, 0.05)'
          }`,
          textAlign: 'center',
          color: theme.palette.text.secondary,
          fontSize: 14
        }}
      >
        <Typography variant="body2" color="inherit">
          Keller Central Chargers Basketball • © {year} - current
        </Typography>
      </Box>
    </Box>
  );
};

export default Layout; 